<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <template v-if="!isCheckLoginPending && !interactionId">
                <v-card>
                    <v-toolbar dense flat color="blue darken-2" dark>
                        <v-toolbar-title>Sign in</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <p>Enter the primary email address for your profile:</p>
                    </v-card-text>
                    <v-form @submit.prevent="signinWithUsername" onSubmit="return false;" @keyup.enter.native.prevent="signinWithUsername" class="mx-4 pb-6">
                        <v-text-field v-model="username" ref="usernameInput" dense solo color="blue darken-2" placeholder="Email" :error-messages="inputError">
                            <template #prepend-inner>
                                <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                            </template>
                        </v-text-field>
                        <RememberMeCheckbox v-model="isRememberMeChecked"/>
                        <v-row no-gutters justify="center">
                            <v-btn @click="signinWithUsername" elevation="4" color="blue darken-2 white--text" class="mt-4">Continue</v-btn>
                        </v-row>
                    </v-form>
                </v-card>
                <p class="mt-6 mb-12 grey--text text--darken-2 text-center">No profile yet? <router-link :to="{ name: 'signup' }">Sign up</router-link></p>
            </template>
            <v-card v-if="isCheckLoginPending" class="mb-6">
                <v-card-text>
                    <p v-if="!loginshieldStartError">Please wait...</p>
                    <p v-if="loginshieldStartError" class="red--text">Service unavailable. Please try again later.</p>
                </v-card-text>
                <!-- TODO: for LoginShield iframe... -->
                <!-- <v-row justify="center" v-show="loginWithLoginShield" class="ma-0 pt-5" style="width: 100%;">
                    <div id="loginshield-content" style="width: 100%; height: 600px;"></div>
                </v-row> -->
            </v-card>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send a verification email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
            <v-alert type="error" v-if="loginshieldStartError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
/* hide message area under the remember me checkbox */
.v-input.remember-me .v-messages {
    display: none;
}
.v-simple-checkbox {
    height: 24px;
}
</style>

<script>
// import { toText } from '@libertyio/time-util-js';
import { mapState } from 'vuex';
import { isValidEmail } from '@/sdk/input';
import RememberMeCheckbox from '@/components/RememberMeCheckbox.vue';
// import { loginshieldInit } from '@loginshield/realm-client-browser';
// import { client } from '@/client';
// function loginshieldInit() {
//     return null; // TODO: we should make a loginfront sdk with iframe like loginshield, so it can be used without redirecting user to another site
// }

// TODO: use loginfront sdk to login, instead of having it built in here

export default {
    components: {
        RememberMeCheckbox,
    },
    data: () => ({
        isCheckLoginPending: false,
        interactionId: null, // null on when user first arrives, has value after email verification
        username: '',
        submitTimestamp: null,
        checkEmail: false,
        verificationExpires: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
        loginWithLoginShield: false,
        loginshieldStartError: false,
        isRememberMeChecked: null,
    }),

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isUsernameFormComplete() {
            return typeof this.username === 'string' && this.username.length > 0;
        },
        isPasswordFormComplete() {
            return typeof this.password === 'string' && this.password.length > 0;
        },
    },

    watch: {
        focus() {
            this.$nextTick(() => {
                setTimeout(() => { this.activate('usernameInput'); }, 1);
            });
        },
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        isRememberMeChecked(newValue) {
            console.log(`Login.vue: isRememberMeChecked watcher: storing ${newValue}`);
            localStorage.setItem('rememberMe', newValue);
        },
    },

    methods: {
        reset() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            this.checkEmail = false;
            this.verificationExpires = null;
        },
        async signinWithUsername() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.reset();
                if (typeof this.username !== 'string' || this.username.trim().length === 0 || !isValidEmail(this.username)) {
                    this.inputError = 'Please enter an email address';
                    this.inputErrorTimeout = setTimeout(() => { this.inputError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$store.commit('loading', { signinWithUsername: true });
                const request = {
                    email: this.username,
                    remember_me: this.isRememberMeChecked,
                    next: this.next, // TODO: check if this should be a route name or URL
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.startLogin(request);
                console.log(`Login.vue: response ${JSON.stringify(response)}`);
                await this.handleSigninResponse(response);
            } catch (err) {
                console.error('failed to sign in', err);
                await this.handleSigninError(err);
            } finally {
                this.$store.commit('loading', { signinWithUsername: false });
            }
        },
        /**
         * Call this only when you have an interactionId where the interaction type is 'login'
         */
        async signinWithInteraction() {
            try {
                this.reset();
                if (typeof this.interactionId !== 'string') {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$store.commit('loading', { signinWithInteraction: true });
                const request = {
                    interactionId: this.interactionId,
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.startLogin(request);
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);

                await this.handleSigninResponse(response);
            } catch (err) {
                console.error('failed to sign up', err);
                await this.handleSigninError(err);
            } finally {
                this.$store.commit('loading', { signinWithInteraction: false });
            }
        },
        async handleSigninResponse(response) {
            // email is not verified, redirect to email verification
            if (response.status === 'check_email' && response.interactionId) {
                this.$router.push({ name: 'verify-email', query: { i: response.interactionId } });
                return;
            }

            // email is verified but user not registered here; redirect to signup
            if (response.status === 'signup_required' && response.email) {
                this.$router.push({ name: 'signup', query: { email: response.email } });
                return;
            }
            if (response.status === 'signup_required') {
                this.$router.push({ name: 'signup' });
                return;
            }

            // user record was created; next step is get the app or set up LoginShield
            if (response.status === 'setup_required') {
                // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                await this.$store.dispatch('loadSession');
                this.$router.push({ name: 'dashboard' }); // TODO: may need to redirect somewhere else for setup
                return;
            }

            // user is already authenticated with same email address
            if (response.status === 'authenticated') {
                this.$router.push({ name: 'dashboard' });
                return;
            }

            // server started a login request and we need to redirect to specified URL to continue
            if (response.status === 'redirect') {
                // use replace so that when user taps 'back' button from there, they won't
                // end up being redirected again to where they just wanted to come back from
                if (typeof window.location.replace === 'function') {
                    window.location.replace(response.redirect);
                } else {
                    // TODO: also show link for user to click
                    window.location.href = response.redirect;
                }
            }

            console.error(`signupWithUsername error: unexpected status from server: ${JSON.stringify(response.status)}`);
            this.serverError = true;
            this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
        },
        async handleSigninError(err) {
            if (err.response?.status) {
                console.error(`response status: ${err.response.status}`);
                // TODO: 300 error codes? server shouldn't be redirecting us...
                if (err.response.status === 403) {
                    this.reset();
                    this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                    this.forbiddenError = true;
                    this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                } else if (err.response.status >= 400 && err.response.status < 500) {
                    this.requestError = true;
                    this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                } else if (err.response.status >= 500) {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } else {
                this.serverError = true;
                this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
            }
        },
        async loadInteraction() {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const response = await this.$client.main().interaction.get(this.interactionId);
                console.log(`response: ${JSON.stringify(response)}`); // {"type":"verify_email","content":{"antiphishing":true,"email":"jonathan@buhacoff.net","intent":"signup","intent_data":{"username":"jonathan@buhacoff.net"},"brandprofile":"etherlink", "status": "new", "status_on": 1655086282382},"not_after":1655086282382}
                const { type, content, duration } = response;
                if (type !== 'login') {
                    this.forbiddenError = true; // TODO: should we have a different error for "you got here with an invalid interaction, its not even the right type" ?
                    // we don't do a timeout for this because this error is not recoverable on this page... user would have to go back to previous page to fix it
                    // this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.duration = duration; // TODO: show in UI that user has X time to click on verification email ;  this duration is in millis, need to round to nearest minute then convert to text like "the verification email will expire in about 15 minutes"
                const { brandprofile, status, username } = content;
                this.brandprofile = brandprofile; // start loading brand colors and logos
                this.status = status;
                if (status === 'new') {
                    // the user arrived here with verified email, so ask server to start actual the signin process where we ask for a credential
                    this.signinWithInteraction();
                } else if (status === 'setup_required') {
                    // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                    await this.$store.dispatch('loadSession');
                    this.$router.push({ name: 'dashboard' }); // TODO: may need to redirect somewhere else for setup
                    return;
                } else if (status === 'signup_required' && username) {
                    this.$router.push({ name: 'signup', query: { email: username } });
                } else if (status === 'signup_required') {
                    this.$router.push({ name: 'signup' });
                } else if (status === 'authenticated') {
                    await this.$store.dispatch('loadSession');
                    this.$router.push({ name: 'dashboard' });
                } else {
                    console.log('unexpected status');
                    this.serverError = true;
                }
            } catch (err) {
                console.error('failed to load interaction', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.reset();
                        // this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
                this.isViewReady = true;
            }
        },
        // onSelectBrand({ alias }) {
        //     if (this.$route.query.intent === 'unsubscribe') {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias }, hash: '#unsubscribe' });
        //     } else {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias } });
        //     }
        // },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        switchAccount() {
            this.interactionId = null;
            this.reset();
            this.$nextTick(() => {
                setTimeout(() => { this.activate('usernameInput'); }, 1);
            });
        },
        async checkLogin() {
            try {
                this.$store.commit('loading', { checkLogin: true });
                this.isCheckLoginPending = true;
                if (this.$route.query.loginfront) {
                    const { isAuthenticated } = await this.$client.main().authn.checkLogin({ token: this.$route.query.loginfront });
                    if (isAuthenticated) {
                        await this.$store.dispatch('loadSession');
                        await this.$store.dispatch('loadUser');
                        this.redirectAfterLogin();
                        return;
                    }
                    this.$store.commit('setSession', { isAuthenticated: false });
                    this.$store.commit('setUser', {});
                } else {
                    // shouldn't get here without a token, but if we do then reset the form completely
                    this.reset();
                    this.username = null;
                    this.interactionId = null;
                    this.isCheckLoginPending = false;
                }
            } catch (err) {
                console.error('start login failed', err);
                this.loginshieldStartError = true;
            } finally {
                this.$store.commit('loading', { checkLogin: false });
            }
        },
        resetErrors() {
            this.passwordError = false;
            this.loginshieldStartError = false;
        },
        resetLoginForm() {
            this.loginWithLoginShield = false;
            this.loginPasswordInput = false;
            this.loginUsernameInput = true;
            this.username = '';
            this.password = '';
            this.$refs.usernameField.reset();
            this.$refs.passwordField.reset();
            this.$nextTick(() => this.$refs.usernameField.focus());
        },
        async loginUsername() {
            if (!this.isUsernameFormComplete) {
                return;
            }
            /*
            this.loginUsernameInput = false;
            this.loginWithLoginShield = true;
            this.startLoginShield({
                username: this.username,
                interactionId: this.interactionId,
                nextRoute: this.next,
                rememberMe: true,
            });
            */
            console.log('Login.vue: loginUsername');
            this.passwordError = false;

            try {
                this.$store.commit('loading', { loginUsername: true });
                const { /* isAuthenticated, */ mechanism } = await this.$client.main().authn.login({
                    username: this.username,
                });
                if (mechanism === 'password') {
                    this.loginUsernameInput = false;
                    this.loginPasswordInput = true;
                    this.$nextTick(() => this.$refs.passwordField.focus());
                } else if (mechanism === 'loginshield') {
                    this.loginUsernameInput = false;
                    this.loginWithLoginShield = true;
                    this.startLoginShield({
                        username: this.username,
                        interactionId: this.interactionId,
                        nextRoute: this.next,
                        rememberMe: true, /* this.isRememberMeChecked */
                    });
                } else {
                    this.passwordError = true;
                    this.resetLoginForm();
                }
            } finally {
                this.$store.commit('loading', { loginUsername: false });
            }
        },
        async redirectAfterLogin({ nextInteractionId } = {}) {
            if (nextInteractionId) {
                const nextInteraction = await this.$store.dispatch('loadInteraction', nextInteractionId);
                console.log('finishLoginShield: next interaction: %o', nextInteraction);
                if (nextInteraction && nextInteraction.type) {
                    switch (nextInteraction.type) {
                    case 'require_login':
                        this.$router.push(nextInteraction.state.redirect);
                        return;
                    default:
                        this.$router.push({ path: '/dashboard', query: { i: nextInteractionId } });
                        return;
                    }
                }
            }
            if (this.next) {
                this.$router.push(this.next);
                return;
            }
            this.$router.push('/dashboard');
        },
    },

    mounted() {
        /*
        The first time user arrives on this page, there would not be an interaction id in the query,
        but there could be an email address to pre-fill if the user is coming from another activity
        where they already entered it, so they don't need to enter it again here (but it's editable
        so they can still change it if they want to).

        The second time the user arrives on this page (after email verification is completed), there
        will be an interaction id in the query, and we can load the username from the server. At this
        point the username is not editable (because it's what the user verified) but if user wants
        to change it we can reset the UI by clearing the interaction id and allowing the user to enter
        a new email address and start over with verification. With this interaction id, we are
        authorized to check if the user already exists and to start the sign in process.

        The third time the user arrives on this page, after returning from LoginShield, there will
        be a token in the query parameters. If this token is present, we don't need to check for
        username or interaction id because the token is all we need to continue (username will
        be in the verification response when we check the token).
        */

        const rememberMeStored = localStorage.getItem('rememberMe');
        this.isRememberMeChecked = rememberMeStored === 'true';

        // TODO: rename from loginfront to loginshield
        if (this.$route.query.loginfront) {
            this.checkLogin();
            return;
        }

        this.username = this.$route.query.email ?? '';

        this.interactionId = this.$route.query.i;

        if (this.interactionId) {
            this.loadInteraction();
        } else {
            this.$nextTick(() => {
                setTimeout(() => { this.activate('usernameInput'); }, 1);
            });

            // if another view redirected here with the intent of returning, it has to provide
            // the path and optional query parameters to redirect the user after login; the path
            // MUST be a relative path because this is not the right place for unrelated logic
            // about where we can redirect the user; the 'next' route could then redirect the
            // user to an offsite URL if needed.
            // We only check for this on first visit to the page, because once the user has
            // submitted email address and done email verification or returned from LoginShield
            // this next route information would already be stored in the interaction.
            const { next } = this.$route.query;
            if (typeof next === 'string' && next.startsWith('/')) {
                this.next = next;
            }
        }
    },
};
</script>
