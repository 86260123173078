<template>
    <v-row no-gutters justify="start" align-content="top">
        <v-col style="flex-grow: 0;">
            <v-simple-checkbox v-model="isRememberMeChecked" color="blue darken-2"></v-simple-checkbox>
        </v-col>
        <v-col style="flex-grow: 1;">
            <p class="mb-0 grey--text text--darken-1" @click="isRememberMeChecked = !isRememberMeChecked" style="cursor: pointer;">Remember me for 90 days</p>
            <p class="mb-0 grey--text text--darken-1 text-caption"><a href="#" @click.prevent="showRememberMeHelp = !showRememberMeHelp">This is a trusted device I use regularly</a></p>
            <p class="mb-0 grey--text text--darken-1 text-caption" style="line-height: 1.2;" v-if="showRememberMeHelp">When you return using the same browser, you can skip the email verification.<!-- TODO: "learn more" link to open a new window --></p>
            <p class="mb-0 grey--text text--darken-1 text-caption" style="line-height: 1.2;" v-if="showRememberMeHelp">For your security, do NOT check this box on a shared or public computer.</p>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['value'],
    data: () => ({
        isRememberMeChecked: null,
        showRememberMeHelp: false,
    }),
    watch: {
        isRememberMeChecked(newValue, oldValue) {
            if (oldValue !== null) {
                this.$emit('input', newValue);
            }
        },
    },
    mounted() {
        this.isRememberMeChecked = this.value;
    },
};
</script>
